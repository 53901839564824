@use "@angular/material" as mat;

@include mat.core();

@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700;900&display=swap");


$enable-cssgrid: true;

$primary: #2980b9;

@import "cgcomponents";


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



// set defauly font
$font-family: "Inter", sans-serif;

html,
body {
  margin: 0;
  font-family: $font-family !important;
  height: 100%;
  overflow: hidden;
}

$primary: #2980b9;
$primary-lighter: #c0c5cb;
$primary-darker: #c0c5cb;

$dark-primary-text: rgba(0, 0, 0, 0.87);
$light-primary-text: rgba(255, 255, 255, 1);

$cg-palette: (
  50: $primary,
  100: $primary,
  200: $primary,
  300: $primary,
  400: $primary,
  500: $primary,
  600: $primary,
  700: $primary,
  800: $primary,
  900: $primary,
  A100: $primary,
  A200: $primary,
  A400: $primary,
  A700: $primary,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$primary-map: mat.define-palette($cg-palette);

$accent: #e67e22;

$accent-map: mat.define-palette((main: $accent,
      lighter: $accent,
      darker: $accent,
      100: $accent,
      200: $accent,
      500: $accent,
      700: $accent,
      // For slide toggle,
      contrast: (main: #000,
        lighter: #000,
        darker: #000,
      ),
    ));

// Typography
$body-1: mat.define-typography-level($font-family: $font-family,
    $font-weight: 400,
    $font-size: 1rem,
    $letter-spacing: normal,
  );

$button: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 12px,
    $letter-spacing: normal,
  );

$body-2: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 0.875rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$subtitle-1: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 0.875rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$subtitle-2: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 0.75rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$button: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 12px,
    $letter-spacing: normal,
  );

$caption: mat.define-typography-level($font-family: $font-family,
    $font-weight: 400,
    $font-size: 0.75rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

// Headlines
$h1: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 2.5rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$h2: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 2rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$h3: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 1.75rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$h4: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 1.5rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$h5: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 1.25rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$h6: mat.define-typography-level($font-family: $font-family,
    $font-weight: 500,
    $font-size: 1rem,
    $line-height: 1.25,
    $letter-spacing: normal,
  );

$primary-theme: mat.define-light-theme((color: (primary: $primary-map,
        accent: $accent-map,
      ),
      typography: mat.define-typography-config($font-family: $font-family,
        $subtitle-1: $subtitle-1,
        $subtitle-2: $subtitle-2,
        $body-1: $body-1,
        $body-2: $body-2,
        $button: $button,
        $caption: $caption,

        $headline-1: $h1,
        $headline-2: $h2,
        $headline-3: $h3,
        $headline-4: $h4,
        $headline-5: $h5,
        $headline-6: $h6,
      ),
      density: 0,
    ));

@include mat.all-component-themes($primary-theme);

// Custom styles: some may need to be moved to the theme or deleted
.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";

  .mat-badge-content {
    font-family: "Roboto";
  }
}

.bg-white {
  background-color: white;
}

.spacer {
  flex: 1 1 auto;
}

.delete-icon {
  color: red;
}

.container-card {
  border-radius: 0px;
  opacity: 0;
  overflow: hidden;
}

html,
body {
  height: 100%;
}

.mat-toolbar .mat-primary {
  color: white;
}

.bg-white {
  background-color: white !important;
}

.text-left {
  text-align: left;
}

.button-small {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &>*[role="img"] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mat-mdc-button-touch-target {
    width: 24px !important;
    height: 24px !important;
  }
}

.error-snackbar {
  color: white;
  --mdc-snackbar-container-color: #e74c3c;

  .mat-simple-snackbar-action {
    color: white;
  }

  --mat-mdc-snack-bar-button-color: white;
}

.success-snackbar {
  color: white;
  --mdc-snackbar-container-color: #27ae60;

  .mat-simple-snackbar-action {
    color: white;
  }

  --mat-mdc-snack-bar-button-color: white;
}

a {
  cursor: pointer;
  font-weight: 500;
}

a:hover,
.action-button:hover {
  color: #8ea9bc !important;
  cursor: pointer !important;
}

a.navIcon,
a.navIcon-last {
  color: #2980b9 !important;
}

a.navIcon:hover {
  color: #8ea9bc !important;
  cursor: pointer !important;
}

a.navIcon-last:hover {
  cursor: default !important;
}