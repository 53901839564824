
a {
  text-decoration: none;
  color: $primary;
}

/*
* Content card
*
*/
.content-card {
  background-color: white;
}

.content-card-content {
  padding: 20px;
}

/*
* Information table style
*
*/

$cell-padding: 10px;

// Custom table style
.info-table {
  margin: 10px;
  margin-left: 0;
  border-collapse: collapse;
  font-size: 12px;
  text-align: left;
  vertical-align: top;
  width: 100%;
}

.info-table th {
  padding: $cell-padding;
  text-align: left;
  width: 150px;
  vertical-align: top;
  color: #484848;
}

.info-table th p {
  margin: 0;
}

.info-table td p {
  margin: 0;
}

.info-table td {
  padding: $cell-padding;
  min-width: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.info-table > tr:nth-child(odd) {
  background-color: #f1f1f1;
}
.info-table > tr:nth-child(even) {
  background-color: #fcfcfc
}

.align-right {
  text-align: right;
}

.section-title {
  font-weight: 600;
  font-size: 14px;
}
